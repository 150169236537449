exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-balnk-js": () => import("./../../../src/pages/balnk.js" /* webpackChunkName: "component---src-pages-balnk-js" */),
  "component---src-pages-blogs-patents-js": () => import("./../../../src/pages/blogs/patents.js" /* webpackChunkName: "component---src-pages-blogs-patents-js" */),
  "component---src-pages-canvas-builder-js": () => import("./../../../src/pages/canvas-builder.js" /* webpackChunkName: "component---src-pages-canvas-builder-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inventor-resources-js": () => import("./../../../src/pages/inventor-resources.js" /* webpackChunkName: "component---src-pages-inventor-resources-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-our-science-technology-js": () => import("./../../../src/pages/our-science-technology.js" /* webpackChunkName: "component---src-pages-our-science-technology-js" */),
  "component---src-pages-patent-guide-6-benefits-to-using-provisionalbuilder-js": () => import("./../../../src/pages/patent-guide/6-benefits-to-using-provisionalbuilder.js" /* webpackChunkName: "component---src-pages-patent-guide-6-benefits-to-using-provisionalbuilder-js" */),
  "component---src-pages-patent-guide-9-important-elements-of-a-provisional-patents-js": () => import("./../../../src/pages/patent-guide/9-important-elements-of-a-provisional-patents.js" /* webpackChunkName: "component---src-pages-patent-guide-9-important-elements-of-a-provisional-patents-js" */),
  "component---src-pages-patent-guide-ask-yourself-these-7-questions-to-determine-whether-you-should-get-a-patent-js": () => import("./../../../src/pages/patent-guide/ask-yourself-these-7-questions-to-determine-whether-you-should-get-a-patent.js" /* webpackChunkName: "component---src-pages-patent-guide-ask-yourself-these-7-questions-to-determine-whether-you-should-get-a-patent-js" */),
  "component---src-pages-patent-guide-designpatent-js": () => import("./../../../src/pages/patent-guide/designpatent.js" /* webpackChunkName: "component---src-pages-patent-guide-designpatent-js" */),
  "component---src-pages-patent-guide-how-do-i-install-provisionalbuilder-js": () => import("./../../../src/pages/patent-guide/how-do-i-install-provisionalbuilder.js" /* webpackChunkName: "component---src-pages-patent-guide-how-do-i-install-provisionalbuilder-js" */),
  "component---src-pages-patent-guide-how-do-i-start-an-application-on-provisionalbuilder-js": () => import("./../../../src/pages/patent-guide/how-do-i-start-an-application-on-provisionalbuilder.js" /* webpackChunkName: "component---src-pages-patent-guide-how-do-i-start-an-application-on-provisionalbuilder-js" */),
  "component---src-pages-patent-guide-how-does-provisionalbuilder-work-js": () => import("./../../../src/pages/patent-guide/how-does-provisionalbuilder-work.js" /* webpackChunkName: "component---src-pages-patent-guide-how-does-provisionalbuilder-work-js" */),
  "component---src-pages-patent-guide-how-to-do-patent-search-yourself-diy-the-right-way-js": () => import("./../../../src/pages/patent-guide/how-to-do-patent-search-yourself-diy-the-right-way.js" /* webpackChunkName: "component---src-pages-patent-guide-how-to-do-patent-search-yourself-diy-the-right-way-js" */),
  "component---src-pages-patent-guide-how-to-know-if-your-invention-is-novel-js": () => import("./../../../src/pages/patent-guide/how-to-know-if-your-invention-is-novel.js" /* webpackChunkName: "component---src-pages-patent-guide-how-to-know-if-your-invention-is-novel-js" */),
  "component---src-pages-patent-guide-how-to-start-your-utility-patent-application-process-in-4-stephow-to-use-a-professional-patent-searcher-js": () => import("./../../../src/pages/patent-guide/how-to-start-your-utility-patent-application-process-in-4-stephow-to-use-a-professional-patent-searcher.js" /* webpackChunkName: "component---src-pages-patent-guide-how-to-start-your-utility-patent-application-process-in-4-stephow-to-use-a-professional-patent-searcher-js" */),
  "component---src-pages-patent-guide-how-to-start-your-utility-patent-application-process-in-4-steps-js": () => import("./../../../src/pages/patent-guide/how-to-start-your-utility-patent-application-process-in-4-steps.js" /* webpackChunkName: "component---src-pages-patent-guide-how-to-start-your-utility-patent-application-process-in-4-steps-js" */),
  "component---src-pages-patent-guide-iix-what-happens-after-filing-a-utility-patent-application-js": () => import("./../../../src/pages/patent-guide/iix-what-happens-after-filing-a-utility-patent-application.js" /* webpackChunkName: "component---src-pages-patent-guide-iix-what-happens-after-filing-a-utility-patent-application-js" */),
  "component---src-pages-patent-guide-js": () => import("./../../../src/pages/patent-guide.js" /* webpackChunkName: "component---src-pages-patent-guide-js" */),
  "component---src-pages-patent-guide-one-year-rule-js": () => import("./../../../src/pages/patent-guide/one-year-rule.js" /* webpackChunkName: "component---src-pages-patent-guide-one-year-rule-js" */),
  "component---src-pages-patent-guide-understand-american-invents-act-aia-js": () => import("./../../../src/pages/patent-guide/understand-american-invents-act-aia.js" /* webpackChunkName: "component---src-pages-patent-guide-understand-american-invents-act-aia-js" */),
  "component---src-pages-patent-guide-what-are-optional-elements-of-a-provisional-patent-js": () => import("./../../../src/pages/patent-guide/what-are-optional-elements-of-a-provisional-patent.js" /* webpackChunkName: "component---src-pages-patent-guide-what-are-optional-elements-of-a-provisional-patent-js" */),
  "component---src-pages-patent-guide-what-are-the-benefits-vs-drawbacks-of-filing-a-provisional-patent-js": () => import("./../../../src/pages/patent-guide/what-are-the-benefits-vs-drawbacks-of-filing-a-provisional-patent.js" /* webpackChunkName: "component---src-pages-patent-guide-what-are-the-benefits-vs-drawbacks-of-filing-a-provisional-patent-js" */),
  "component---src-pages-patent-guide-what-are-the-five-patent-classes-js": () => import("./../../../src/pages/patent-guide/what-are-the-five-patent-classes.js" /* webpackChunkName: "component---src-pages-patent-guide-what-are-the-five-patent-classes-js" */),
  "component---src-pages-patent-guide-what-do-i-do-after-provisionalbuilder-is-installed-js": () => import("./../../../src/pages/patent-guide/what-do-i-do-after-provisionalbuilder-is-installed.js" /* webpackChunkName: "component---src-pages-patent-guide-what-do-i-do-after-provisionalbuilder-is-installed-js" */),
  "component---src-pages-patent-guide-what-to-do-after-the-uspto-has-issued-your-patent-js": () => import("./../../../src/pages/patent-guide/what-to-do-after-the-uspto-has-issued-your-patent.js" /* webpackChunkName: "component---src-pages-patent-guide-what-to-do-after-the-uspto-has-issued-your-patent-js" */),
  "component---src-pages-patent-guide-when-is-invention-date-established-js": () => import("./../../../src/pages/patent-guide/when-is-invention-date-established.js" /* webpackChunkName: "component---src-pages-patent-guide-when-is-invention-date-established-js" */),
  "component---src-pages-patent-strategy-js": () => import("./../../../src/pages/patent-strategy.js" /* webpackChunkName: "component---src-pages-patent-strategy-js" */),
  "component---src-pages-patentfaqs-after-submit-ppa-js": () => import("./../../../src/pages/patentfaqs/after-submit-ppa.js" /* webpackChunkName: "component---src-pages-patentfaqs-after-submit-ppa-js" */),
  "component---src-pages-patentfaqs-file-a-utility-patent-application-js": () => import("./../../../src/pages/patentfaqs/file-a-utility-patent-application.js" /* webpackChunkName: "component---src-pages-patentfaqs-file-a-utility-patent-application-js" */),
  "component---src-pages-patentfaqs-ip-basics-js": () => import("./../../../src/pages/patentfaqs/ip-basics.js" /* webpackChunkName: "component---src-pages-patentfaqs-ip-basics-js" */),
  "component---src-pages-patentfaqs-js": () => import("./../../../src/pages/patentfaqs.js" /* webpackChunkName: "component---src-pages-patentfaqs-js" */),
  "component---src-pages-patentfaqs-need-for-patent-attorney-or-agent-js": () => import("./../../../src/pages/patentfaqs/need-for-patent-attorney-or-agent.js" /* webpackChunkName: "component---src-pages-patentfaqs-need-for-patent-attorney-or-agent-js" */),
  "component---src-pages-patentfaqs-patent-basics-js": () => import("./../../../src/pages/patentfaqs/patent-basics.js" /* webpackChunkName: "component---src-pages-patentfaqs-patent-basics-js" */),
  "component---src-pages-patentfaqs-patent-issued-js": () => import("./../../../src/pages/patentfaqs/patent-issued.js" /* webpackChunkName: "component---src-pages-patentfaqs-patent-issued-js" */),
  "component---src-pages-patentfaqs-provisional-builder-js": () => import("./../../../src/pages/patentfaqs/provisional-builder.js" /* webpackChunkName: "component---src-pages-patentfaqs-provisional-builder-js" */),
  "component---src-pages-patentfaqs-provisional-patent-application-js": () => import("./../../../src/pages/patentfaqs/provisional-patent-application.js" /* webpackChunkName: "component---src-pages-patentfaqs-provisional-patent-application-js" */),
  "component---src-pages-patentfaqs-respond-to-office-action-js": () => import("./../../../src/pages/patentfaqs/respond-to-office-action.js" /* webpackChunkName: "component---src-pages-patentfaqs-respond-to-office-action-js" */),
  "component---src-pages-patentfaqs-set-up-an-account-with-the-uspto-js": () => import("./../../../src/pages/patentfaqs/set-up-an-account-with-the-uspto.js" /* webpackChunkName: "component---src-pages-patentfaqs-set-up-an-account-with-the-uspto-js" */),
  "component---src-pages-patentfaqs-utility-patent-application-js": () => import("./../../../src/pages/patentfaqs/utility-patent-application.js" /* webpackChunkName: "component---src-pages-patentfaqs-utility-patent-application-js" */),
  "component---src-pages-patentfaqs-what-happens-after-filing-utility-application-js": () => import("./../../../src/pages/patentfaqs/what-happens-after-filing-utility-application.js" /* webpackChunkName: "component---src-pages-patentfaqs-what-happens-after-filing-utility-application-js" */),
  "component---src-pages-press-releases-js": () => import("./../../../src/pages/press-releases.js" /* webpackChunkName: "component---src-pages-press-releases-js" */),
  "component---src-pages-probono-js": () => import("./../../../src/pages/probono.js" /* webpackChunkName: "component---src-pages-probono-js" */),
  "component---src-pages-provisional-builder-js": () => import("./../../../src/pages/provisional-builder.js" /* webpackChunkName: "component---src-pages-provisional-builder-js" */),
  "component---src-pages-tag-tags-js": () => import("./../../../src/pages/tag/tags.js" /* webpackChunkName: "component---src-pages-tag-tags-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-webinar-3-step-definitive-strategy-guide-to-protect-your-software-innovations-js": () => import("./../../../src/pages/webinar-3-step-definitive-strategy-guide-to-protect-your-software-innovations.js" /* webpackChunkName: "component---src-pages-webinar-3-step-definitive-strategy-guide-to-protect-your-software-innovations-js" */),
  "component---src-pages-webinar-3-steps-strategy-guide-to-protect-your-software-innovations-js": () => import("./../../../src/pages/webinar-3-steps-strategy-guide-to-protect-your-software-innovations.js" /* webpackChunkName: "component---src-pages-webinar-3-steps-strategy-guide-to-protect-your-software-innovations-js" */),
  "component---src-pages-webinar-ai-meets-ip-welcome-to-the-future-of-patent-drafting-js": () => import("./../../../src/pages/webinar-ai-meets-ip-welcome-to-the-future-of-patent-drafting.js" /* webpackChunkName: "component---src-pages-webinar-ai-meets-ip-welcome-to-the-future-of-patent-drafting-js" */),
  "component---src-pages-webinar-ai-startups-are-losing-millions-to-patent-infringement-wars-how-to-defend-your-innovation-now-js": () => import("./../../../src/pages/webinar-ai-startups-are-losing-millions-to-patent-infringement-wars-how-to-defend-your-innovation-now.js" /* webpackChunkName: "component---src-pages-webinar-ai-startups-are-losing-millions-to-patent-infringement-wars-how-to-defend-your-innovation-now-js" */),
  "component---src-pages-webinar-an-ai-powered-patent-drafting-solution-a-must-try-for-entrepreneurs-js": () => import("./../../../src/pages/webinar-an-ai-powered-patent-drafting-solution-a-must-try-for-entrepreneurs.js" /* webpackChunkName: "component---src-pages-webinar-an-ai-powered-patent-drafting-solution-a-must-try-for-entrepreneurs-js" */),
  "component---src-pages-webinar-an-ai-powered-patent-drafting-solution-js": () => import("./../../../src/pages/webinar-an-ai-powered-patent-drafting-solution.js" /* webpackChunkName: "component---src-pages-webinar-an-ai-powered-patent-drafting-solution-js" */),
  "component---src-pages-webinar-avoid-the-ip-traps-that-destroy-hardware-startups-js": () => import("./../../../src/pages/webinar-avoid-the-ip-traps-that-destroy-hardware-startups.js" /* webpackChunkName: "component---src-pages-webinar-avoid-the-ip-traps-that-destroy-hardware-startups-js" */),
  "component---src-pages-webinar-big-brands-are-watching-protect-your-consumer-electronics-ip-before-they-steal-it-js": () => import("./../../../src/pages/webinar-big-brands-are-watching--protect-your-consumer-electronics-ip-before-they-steal-it.js" /* webpackChunkName: "component---src-pages-webinar-big-brands-are-watching-protect-your-consumer-electronics-ip-before-they-steal-it-js" */),
  "component---src-pages-webinar-blockchain-js": () => import("./../../../src/pages/webinar-blockchain.js" /* webpackChunkName: "component---src-pages-webinar-blockchain-js" */),
  "component---src-pages-webinar-blockchain-trump-is-in-blockchain-is-in-js": () => import("./../../../src/pages/webinar-blockchain-trump-is-in-blockchain-is-in.js" /* webpackChunkName: "component---src-pages-webinar-blockchain-trump-is-in-blockchain-is-in-js" */),
  "component---src-pages-webinar-demystifying-patent-claims-and-specifications-js": () => import("./../../../src/pages/webinar-demystifying-patent-claims-and-specifications.js" /* webpackChunkName: "component---src-pages-webinar-demystifying-patent-claims-and-specifications-js" */),
  "component---src-pages-webinar-from-concept-to-commercialization-the-licensing-pathway-js": () => import("./../../../src/pages/webinar-from-concept-to-commercialization-the-licensing-pathway.js" /* webpackChunkName: "component---src-pages-webinar-from-concept-to-commercialization-the-licensing-pathway-js" */),
  "component---src-pages-webinar-how-to-navigate-the-patenting-journey-js": () => import("./../../../src/pages/webinar-how-to-navigate-the-patenting-journey.js" /* webpackChunkName: "component---src-pages-webinar-how-to-navigate-the-patenting-journey-js" */),
  "component---src-pages-webinar-how-to-patent-digital-health-innovations-before-they-are-copied-js": () => import("./../../../src/pages/webinar-how-to-patent-digital-health-innovations-before-they-are-copied.js" /* webpackChunkName: "component---src-pages-webinar-how-to-patent-digital-health-innovations-before-they-are-copied-js" */),
  "component---src-pages-webinar-intellectual-property-management-for-startups-js": () => import("./../../../src/pages/webinar-intellectual-property-management-for-startups.js" /* webpackChunkName: "component---src-pages-webinar-intellectual-property-management-for-startups-js" */),
  "component---src-pages-webinar-ip-strategy-for-small-business-growth-js": () => import("./../../../src/pages/webinar-ip-strategy-for-small-business-growth.js" /* webpackChunkName: "component---src-pages-webinar-ip-strategy-for-small-business-growth-js" */),
  "component---src-pages-webinar-js": () => import("./../../../src/pages/webinar.js" /* webpackChunkName: "component---src-pages-webinar-js" */),
  "component---src-pages-webinar-leveraging-intellectual-property-for-e-commerce-success-js": () => import("./../../../src/pages/webinar-leveraging-intellectual-property-for-e-commerce-success.js" /* webpackChunkName: "component---src-pages-webinar-leveraging-intellectual-property-for-e-commerce-success-js" */),
  "component---src-pages-webinar-mastering-trademark-protection-a-comprehensive-guide-2-js": () => import("./../../../src/pages/webinar-mastering-trademark-protection-a-comprehensive-guide-2.js" /* webpackChunkName: "component---src-pages-webinar-mastering-trademark-protection-a-comprehensive-guide-2-js" */),
  "component---src-pages-webinar-mastering-trademark-protection-a-comprehensive-guide-js": () => import("./../../../src/pages/webinar-mastering-trademark-protection-a-comprehensive-guide.js" /* webpackChunkName: "component---src-pages-webinar-mastering-trademark-protection-a-comprehensive-guide-js" */),
  "component---src-pages-webinar-navigating-international-patent-laws-as-a-startup-js": () => import("./../../../src/pages/webinar-navigating-international-patent-laws-as-a-startup.js" /* webpackChunkName: "component---src-pages-webinar-navigating-international-patent-laws-as-a-startup-js" */),
  "component---src-pages-webinar-navigating-international-patent-laws-js": () => import("./../../../src/pages/webinar-navigating-international-patent-laws.js" /* webpackChunkName: "component---src-pages-webinar-navigating-international-patent-laws-js" */),
  "component---src-pages-webinar-patent-power-play-turn-your-product-features-into-business-assets-js": () => import("./../../../src/pages/webinar-patent-power-play-turn-your-product-features-into-business-assets.js" /* webpackChunkName: "component---src-pages-webinar-patent-power-play-turn-your-product-features-into-business-assets-js" */),
  "component---src-pages-webinar-trademark-infringement-prevention-and-resolution-js": () => import("./../../../src/pages/webinar-trademark-infringement-prevention-and-resolution.js" /* webpackChunkName: "component---src-pages-webinar-trademark-infringement-prevention-and-resolution-js" */),
  "component---src-pages-webinar-turn-your-product-features-into-business-assets-js": () => import("./../../../src/pages/webinar-turn-your-product-features-into-business-assets.js" /* webpackChunkName: "component---src-pages-webinar-turn-your-product-features-into-business-assets-js" */),
  "component---src-pages-webinar-understanding-patents-from-idea-to-invention-2-js": () => import("./../../../src/pages/webinar-understanding-patents-from-idea-to-invention-2.js" /* webpackChunkName: "component---src-pages-webinar-understanding-patents-from-idea-to-invention-2-js" */),
  "component---src-pages-webinar-understanding-patents-from-idea-to-invention-js": () => import("./../../../src/pages/webinar-understanding-patents-from-idea-to-invention.js" /* webpackChunkName: "component---src-pages-webinar-understanding-patents-from-idea-to-invention-js" */),
  "component---src-pages-webinar-videos-js": () => import("./../../../src/pages/webinar-videos.js" /* webpackChunkName: "component---src-pages-webinar-videos-js" */),
  "component---src-pages-webinar-why-big-automakers-could-erase-your-autonomous-vehicle-innovation-js": () => import("./../../../src/pages/webinar-why-big-automakers-could-erase-your-autonomous-vehicle-innovation.js" /* webpackChunkName: "component---src-pages-webinar-why-big-automakers-could-erase-your-autonomous-vehicle-innovation-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

